import { ElementType, FormElement, FormKitSchemaObject } from '@/types/form';
import {
	faArrowsRepeat,
	faAt,
	faBallotCheck,
	faCalendarClock,
	faCalendarDay,
	faClock,
	faDistributeSpacingVertical,
	faDollarSign,
	faFilePlus,
	faH1,
	faH2,
	faH3,
	faH4,
	faHorizontalRule,
	faInputNumeric,
	faInputText,
	faListDropdown,
	faListRadio,
	faListTimeline,
	faLock,
	faMoneyBill1,
	faParagraph,
	faPhone,
	faQuoteLeft,
	faSlidersSimple,
	faSquareCheck,
	faSquareList,
	faStarSharpHalfStroke,
	faToggleOff,
	faPlusMinus,
} from '@fortawesome/pro-light-svg-icons';

export const availableElements: FormElement[] = [
	{
		id: 1,
		name: 'Text input',
		description: 'Basic free-form text input',
		icon: faInputText,
		type: ElementType.Field,
		schema: {
			$formkit: 'text',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 2,
		name: 'Select list',
		icon: faListDropdown,
		type: ElementType.Field,
		schema: {
			$formkit: 'select',
			placeholder: '---Please select---',
			options: [],
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 3,
		name: 'Toggle switch',
		icon: faToggleOff,
		type: ElementType.Field,
		schema: {
			$formkit: 'toggle',
			onValueLabel: 'Yes',
			offValueLabel: 'No',
			labelPosition: 'secondary',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 4,
		name: 'Secret/Password input',
		icon: faLock,
		type: ElementType.Field,
		schema: {
			$formkit: 'password',
			prefixIcon: 'lock',
			innerClass: {
				relative: true,
				'rounded-md': true,
				'shadow-sm': true,
			},
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 5,
		name: 'Email input',
		icon: faAt,
		type: ElementType.Field,
		schema: {
			$formkit: 'email',
			validation: [['email']],
			prefixIcon: 'mail',
			innerClass: {
				relative: true,
				'rounded-md': true,
				'shadow-sm': true,
			},
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 6,
		name: 'Number input',
		icon: faInputNumeric,
		type: ElementType.Field,
		schema: {
			$formkit: 'number',
			validation: [['number']],
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 7,
		name: 'Number Slider input',
		icon: faSlidersSimple,
		type: ElementType.Field,
		schema: {
			$formkit: 'range',
			validation: [['number']],
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 8,
		name: 'Date input',
		icon: faCalendarDay,
		type: ElementType.Field,
		schema: {
			$formkit: 'date',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 9,
		name: 'Time input',
		icon: faClock,
		type: ElementType.Field,
		schema: {
			$formkit: 'time',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 10,
		name: 'Date/Time input',
		icon: faCalendarClock,
		type: ElementType.Field,
		schema: {
			$formkit: 'datetime-local',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 11,
		name: 'Textarea input',
		icon: faInputText,
		type: ElementType.Field,
		schema: {
			$formkit: 'textarea',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 12,
		name: 'Telephone input',
		icon: faPhone,
		type: ElementType.Field,
		schema: {
			$formkit: 'telephone',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 13,
		name: 'Heading - level 1',
		icon: faH1,
		type: ElementType.Static,
		schema: {
			$el: 'h1',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'text-4xl': true },
			},
			children: 'New heading',
		},
	},
	{
		id: 14,
		name: 'Heading - level 2',
		icon: faH2,
		type: ElementType.Static,
		schema: {
			$el: 'h2',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'text-2xl': true },
			},
			children: 'New subheading',
		},
	},
	{
		id: 15,
		name: 'Divider Line',
		icon: faHorizontalRule,
		type: ElementType.Static,
		schema: {
			$el: 'div',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'py-2': true },
			},
			meta: {
				type: 'divider',
			},
			children: [
				{
					$el: 'div',
					attrs: {
						class: { relative: true },
					},
					children: [
						{
							$el: 'div',
							attrs: {
								class: { absolute: true, 'inset-0': true, flex: true, 'items-center': true },
								'aria-hidden': true,
							},
							children: [
								{
									$el: 'div',
									attrs: {
										class: { 'w-full': true, 'border-t': true },
										style: { 'border-color': '#D1D5DB' },
										'aria-hidden': true,
									},
								},
							],
						},
						{
							$el: 'div',
							attrs: {
								class: { relative: true, flex: true, 'justify-start': true },
								'aria-hidden': true,
							},
							children: [
								{
									$el: 'span',
									attrs: {
										class: {
											'bg-white': true,
											'px-3': true,
											'text-lg': true,
											'font-medium': true,
											'text-gray-900': true,
										},
									},
									children: '',
								},
							],
						},
					],
				},
			],
		},
	},
	{
		id: 16,
		name: 'Currency',
		icon: faDollarSign,
		type: ElementType.Field,
		schema: {
			$formkit: 'number',
			validation: [['number']],
			step: '0.01',
			prefixIcon: 'dollar',
			innerClass: {
				relative: true,
				'rounded-md': true,
				'shadow-sm': true,
			},
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 17,
		name: 'Currency - Whole dollars',
		icon: faMoneyBill1,
		type: ElementType.Field,
		schema: {
			$formkit: 'number',
			validation: [['number']],
			step: '1',
			__raw__sectionsSchema: {
				suffix: {
					$el: 'div',
					attrs: {
						class: 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-500',
					},
					children: '.00',
				},
			},
			prefixIcon: 'dollar',
			innerClass: {
				relative: true,
				'rounded-md': true,
				'shadow-sm': true,
			},
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
			inputClass: {
				'pr-12': true,
			},
		},
	},
	{
		id: 18,
		name: 'Checkbox',
		icon: faSquareCheck,
		type: ElementType.Field,
		schema: {
			$formkit: 'checkbox',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 19,
		name: 'Radio buttons',
		icon: faListRadio,
		type: ElementType.Field,
		schema: {
			$formkit: 'radio',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 20,
		name: 'File upload',
		icon: faFilePlus,
		type: ElementType.Field,
		schema: {
			$formkit: 'file',
			multiple: true,
			accept: '*',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 21,
		name: 'Heading - level 3',
		icon: faH3,
		type: ElementType.Static,
		schema: {
			$el: 'h3',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'text-xl': true },
			},
			children: 'New h3 heading',
		},
	},
	{
		id: 22,
		name: 'Heading - level 4',
		icon: faH4,
		type: ElementType.Static,
		schema: {
			$el: 'h4',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'text-lg': true },
			},
			children: 'New h4 heading',
		},
	},
	{
		id: 23,
		name: 'Heading - level 5',
		icon: faH4,
		type: ElementType.Static,
		schema: {
			$el: 'h5',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'text-base': true },
			},
			children: 'New h5 heading',
		},
	},
	{
		id: 24,
		name: 'Paragraph',
		icon: faParagraph,
		type: ElementType.Static,
		schema: {
			$el: 'p',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'font-normal': true, 'text-base': true },
			},
			children: 'Paragraph of text',
		},
	},
	{
		id: 25,
		name: 'Quote',
		icon: faQuoteLeft,
		type: ElementType.Static,
		schema: {
			$el: 'blockquote',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'border-l-4': true, 'py-1': true, 'pl-4': true, italic: true, 'text-base': true },
			},
			children: [
				{
					$el: 'p',
					children: 'This is a fancy quote',
				},
			],
		},
	},
	{
		id: 26,
		name: 'Spacer',
		icon: faDistributeSpacingVertical,
		type: ElementType.Static,
		schema: {
			$el: 'div',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'font-normal': true, 'text-base': true, 'h-8': true },
			},
			meta: {
				type: 'spacer',
			},
			children: '',
		},
	},
	{
		id: 27,
		name: 'Dropdown',
		icon: faListDropdown,
		type: ElementType.Field,
		schema: {
			$formkit: 'dropdown',
			placeholder: '---Please select---',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 28,
		name: 'Taglist',
		icon: faListTimeline,
		type: ElementType.Field,
		schema: {
			$formkit: 'taglist',
			placeholder: 'Type or select a tag',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 29,
		name: 'Rating',
		icon: faStarSharpHalfStroke,
		type: ElementType.Field,
		schema: {
			$formkit: 'rating',
			ratingIcon: 'star',
			min: 0,
			max: 5,
			step: 1,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 30,
		name: 'Data Display',
		icon: faSquareList,
		type: ElementType.Static,
		schema: {
			$el: 'div',
			attrs: {
				class: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true },
			},
			meta: {
				type: 'display',
			},
			children: [
				{
					$el: 'dt',
					attrs: {
						class: { 'text-sm': true, 'font-medium': true, 'text-gray-500': true },
					},
					children: 'Label',
				},
				{
					$el: 'dd',
					attrs: {
						class: { 'mt-1': true, 'text-sm': true, 'text-gray-900': true },
					},
					children: 'Value',
				},
			],
		},
	},
	{
		id: 31,
		name: 'Repeater',
		icon: faArrowsRepeat,
		type: ElementType.Field,
		schema: {
			$formkit: 'repeater',
			children: [],
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 32,
		name: 'Checkbox group',
		icon: faBallotCheck,
		type: ElementType.Field,
		schema: {
			$formkit: 'checkbox',
			options: [],
			meta: {
				type: 'group',
			},
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
	{
		id: 33,
		name: 'Number Stepper input',
		icon: faPlusMinus,
		type: ElementType.Field,
		schema: {
			$formkit: 'stepper',
			validation: [['number']],
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	},
];

export const loadingElement = (formId: string): FormKitSchemaObject => {
	return {
		$el: 'svg',
		if: '$get(' + formId + ').context.state.loading === true',
		attrs: {
			class: 'animate-spin -ml-1 mr-3 h-5 w-5 text-white',
			xmlns: 'http://www.w3.org/2000/svg',
			fill: 'none',
			viewBox: '0 0 24 24',
		},
		children: [
			{
				$el: 'circle',
				attrs: {
					class: 'opacity-25',
					cx: '12',
					cy: '12',
					r: '10',
					stroke: 'currentColor',
					'stroke-width': '4',
				},
			},
			{
				$el: 'path',
				attrs: {
					class: 'opacity-75',
					fill: 'currentColor',
					d: 'M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z',
				},
			},
		],
	} as FormKitSchemaObject;
};
